import { Link } from "react-router-dom";
import InquiryButton from '../../elements/inquire-button';
import SendButton from '../../elements/send-button';

const Contact = ({ backgroundColor, buttonType, buttonBackgroundColor = "orange", onSend }) => {
    const getContactClassName = () => {
      if (backgroundColor === "green") return "contact-green";
      if (backgroundColor === "blue") return "contact-blue";
      return "contact-white";
    }
    return (
      <div className={getContactClassName()}>
        <div className="row row-standard-no-padding-bottom">
          <div className="col-small">
            <p className="p-without-margin-bottom">
            <b>Pauline Carla Meyer-Beer</b><br />
            </p>
            <p className="small-text-box">
              Dolmetscherin für Deutsch und<br />
              Deutsche Gebärdensprache
            </p>
          </div>
          <div className="col-big flex-row">
            <p className="small-text-box">
              Berlin<br />
              <a href="tel:+491773026877">+49 (0)177 302 6877</a><br />
              <a href="&#x6d;&#x61;&#x69;&#x6c;&#x74;&#x6f;&#x3a;&#x70;&#x6f;&#x73;&#x74;&#x40;&#x6d;&#x65;&#x79;&#x65;&#x72;&#x2d;&#x62;&#x65;&#x65;&#x72;&#x2e;&#x64;&#x65;">
              &#x70;&#x6f;&#x73;&#x74;&#x40;&#x6d;&#x65;&#x79;&#x65;&#x72;&#x2d;&#x62;&#x65;&#x65;&#x72;&#x2e;&#x64;&#x65;</a>
            </p>
             {/** TODO: schauen, ob man das mit getContactClassName schöner lösen kann */}
            <div className="contact-button">
              {
                buttonType === "inquiry"
                ? <InquiryButton color={getContactClassName() === "contact-white" ? "orange" : ""} />
                : <SendButton sentSuccessfully={buttonBackgroundColor === "green"} onSend={onSend}/>
              }
            </div>
          </div>
        </div>
        <div className="row row-contact-links">
          <Link to="/impressum" className="contact-link" >Impressum</Link>
          <Link to="/datenschutzerklaerung" className="contact-link">Datenschutzerklärung</Link>
          <Link to="/agb" className="contact-link">AGB</Link>
          <Link to="/informationspflicht" className="contact-link">Informationspflicht</Link>
        </div>
      </div>
    );
  }
  
  export default Contact;
  