import React from 'react';
import Contact from '../components/contact';
import aboutMe1 from './../images/about_me_1.jpg';
import aboutMe2 from './../images/about_me_2.jpg';

const AboutMe = () => {

  return (
    <div className="body">
      <div className="background-main-1">
        <div className="row row-standard">
          <div className="col-small">
            <img src={aboutMe1} alt="image4"/>
          </div>
          <div className="col-big col-big-margin-top">
            <h2>Über mich</h2>
            <p>
              Jahrgang 1987, aufgewachsen in Berlin
            </p>
            <p>
              Nach dem Abitur Sprachkurse in Deutscher Gebärdensprache und Praktikum im Hilton Hotel Berlin.
            </p>
            <p>
              Das Hotellerie-Gewerbe war allerdings nicht 
              meine Welt und die Entscheidung fiel auf das Studium B.A. Deaf Studies (Sprache und Kultur 
              der Gehörlosengemeinschaft) und Deutsche Gebärdensprache an der Humboldt-Universität 
              zu Berlin.
            </p>
            <p>
              Neben dem Studium Fortbildungen in den Bereichen Entwicklungspsychologische Beratung, Spracherwerb, sprachliche Frühförderung, Kommunikation. Langjährige Tätigkeit in 
              der Kinderbetreuung in Kindertagesstätten, 
              dem damaligen Berliner Elternverein Hörgeschädigter e.V. und in Privathaushalten.
            </p>
            <p className='p-without-margin-bottom'>
              Bachelor-Abschluss 2011. 
            </p>
          </div>
        </div>
        <div className="row row-standard-no-padding-top row-reverse-on-small-screens">
          <div className="col-big col-big-margin-top">
            <p>
              Anschließend ein Jahr in Istanbul&#8200;/&#8200;Türkei. Dort stipendienfinanziertes Praktikum in der Montessori-Kindertagesstätte Küçük Kara Balık Çocuk Evi und intensive Türkisch-Kurse.
            </p>
            <p>
              2012 Rückkehr nach Berlin. Ehrenamt im Rahmen des Projekts Wellcome – Praktische Hilfen für Familien nach der Geburt. Anstellung bei einem freien Träger der Kinder- und Jugendhilfe.
            </p>
            <p>
              2014-2017 Masterstudium Gebärdensprachdolmetschen, ebenfalls an der HU Berlin. Anstellung bei der Eltern-Beratungsstelle des Bundeselternverbands gehörloser Kinder e.V. und Weiterbildung Lösungsfokussierte Beratung an der KHSB. Arbeitsassistenz für einen tauben Arbeitnehmer.
            </p>
            <p>
              2019 staatlich geprüfte Dolmetscherin für Deutsche Gebärdensprache.
            </p>
            <p>
              Fortbildung im Bereich Traumapädagogik&#8200;/&#8200;Traumazentrierte Fachberatung am institut berlin, Schulung zur Hospizbegleiterin, Fortbildung Trauerbegleitung bei Savina Tilmann.
            </p>
            <p className='p-without-margin-bottom'>
              Seit 2019 ehrenamtliches Engagement im Bereich Tierschutz und Tierrechte.
            </p>
          </div>
          <div className="col-small">
            <img src={aboutMe2} alt="image6"/>
          </div>
        </div>
      </div>
      <Contact
        backgroundColor="green"
        buttonType="inquiry"
        />
    </div>
  );
}

export default AboutMe;
