import React from 'react';
import Contact from '../components/contact';

const AGB = () => {

  return (
    <div className="body">
      <div className="home-row">
        <div className="row-flex-start">
          <div className="col-big col-big-margin-top-and-bottom">
            <h2>Inhalt</h2>
            <ol className='content-list'>
              <li>Geltungsbereich</li>
              <li>Vertragsgegenstand</li>
              <li>Umfang des Vertrags</li>
              <li>Honorar</li>
              <li>Zahlung des Honorars</li>
              <li>Verschwiegenheitspflicht</li>
              <li>Leistungsumfang</li>
              <li>Arbeitsbedingungen</li>
              <li>Urheberrecht</li>
              <li>Rücktritt vom Vertrag</li>
              <li>Gerichtsstand</li>
              <li>Salvatorische Klausel</li>
            </ol>
          </div>
          <div className="col-big">
            <h2>AGB</h2>
            <h4>1. Geltungsbereich</h4>
            <p>
              Die nachstehenden Allgemeinen Geschäftsbedingungen gelten für Verträge zwischen der Auftragnehmerin Pauline Carla Meyer-Beer (im Folgenden: Pauline Meyer-Beer) und ihren Auftraggeber*innen (im Folgenden: AG), soweit nicht etwas anderes ausdrücklich vereinbart oder gesetzlich unabdingbar vorgeschrieben ist. Von den Allgemeinen Geschäftsbedingungen abweichende vertragliche Regelungen zwischen Pauline Meyer-Beer und den AG sind nur dann wirksam, wenn sie schriftlich vereinbart worden sind. Es entsteht eine unmittelbare Geschäftsbeziehung zwischen den AG und Pauline Meyer-Beer. 
              <br />
              Die Auftragsbedingungen werden von den AG mit der Auftragserteilung anerkannt und gelten für die gesamte Dauer des Dienstverhältnisses. Allgemeine Geschäftsbedingungen der AG sind für Pauline Meyer-Beer nur verbindlich, wenn sie sie ausdrücklich anerkannt hat.
            </p>
            <h4>2. Vertragsgegenstand</h4>
            <p>
              2.1 Der Gegenstand des Vertrags beinhaltet unter anderem Art, Umfang und Dauer des Einsatzes, Einsatzort sowie, ob der Einsatz in Einzel- oder Doppelbesetzung durchgeführt wird. Pauline  Meyer-Beer ist in der Annahme eines Auftrags frei. Sobald den AG eine Bestätigung über die Auftragsannahme durch Pauline Meyer-Beer (in schriftlicher, mündlicher, fernmündlicher Form, E-Mail oder per SMS oder Messenger, z.B. WhatsApp) vorliegt, gilt der Auftrag als angenommen. Stimmen Angebot und Annahme überein, wird der Auftrag als verbindlich erteilt angesehen.
            </p>
            <p>
              2.2 Wird eine Auftragsbestätigung von Pauline Meyer-Beer an die AG übermittelt und die gewünschte Dienstleistung ohne die vorherige Zustimmung durch die AG zur Auftragsbestätigung in Anspruch genommen, gilt der Vertrag als geschlossen und hat Gültigkeit (stillschweigende Annahme). In diesem Fall waren den AG die Konditionen, zu welchen sie die Dienstleistungen von Pauline Meyer-Beer in Anspruch genommen haben, bekannt.
            </p>
            <p>
              2.3 Pauline Meyer-Beer ist verpflichtet, die Aufträge nach bestem Wissen und Gewissen sowie gemäß der Berufs- und Ehrenordnung für (Gebärdensprach-) Dolmetscher*innen und Übersetzer*innen abzuleisten.
            </p>
            <p>
              2.4 Die von Pauline Meyer-Beer zu erbringenden Tätigkeiten richten sich im Einzelfall nach der im jeweiligen Angebot&#8200;/&#8200;in der jeweiligen Auftragsbestätigung. Zu darüberhinausgehenden Tätigkeiten ist Pauline Meyer-Beer grundsätzlich nicht verpflichtet.
            </p>
            <p>
              2.5 Überschreitet die Dolmetschzeit 60 Minuten, wird der Termin in der Regel von einem Dolmetsch-Team bestehend aus zwei Dolmetscher*innen übernommen. Bei rechtzeitiger Auftragsvergabe und Information über die Dauer des Termins organisiert in der Regel Pauline  Meyer-Beer eine*n zweite*n Dolmetscher*in. 
              <br />
              Dies richtet sich nach Absprachen im Einzelfall. Überschreitet die Dolmetschzeit 60 Minuten und es ist – gleich aus welchen Gründen – kein*e zweite*r Dolmetscher*in verfügbar, behält sich Pauline Meyer-Beer vor, den Auftrag nach 75 Minuten abzubrechen. Es wird die bestellte Zeit in Rechnung gestellt. Wird die bestellte Zeit überschritten, wird die tatsächlich geleistete Dolmetschzeit in Rechnung gestellt. 
            </p>
            <p>
              2.6 Als veranschlagte Einsatzzeit gilt prinzipiell die Summe aus Dolmetschzeit vor Ort, Fahrt- und Wartezeiten. Die Honorierung der anfallenden Vorbereitungszeit wird im entsprechenden Einzelfall geregelt. Bei der Dolmetschzeit gilt eine zu berechnende Mindesteinheit von einer Stunde. Danach wird die Dienstleistung, soweit nicht anders vereinbart, im Halbstundentakt berechnet.
            </p>
            <p>
              2.7 Bei den Fahrtkosten, die pro Einsatz erhoben werden, handelt es sich entweder um gefahrene Autokilometer oder angefallene Kosten für den ÖPNV&#8200;/&#8200;die DB. Außerdem können anfallende Parkgebühren, außerordentliche Gebühren und Kosten, z. B. Eintrittsgelder, Spesen oder Ähnliches den AG in Rechnung gestellt werden.
            </p>
            <h4>3. Umfang des Vertrags</h4>
            <p>
              3.1. Die Einsatzzeit richtet sich nach den vertraglichen Vereinbarungen. Sollte die Tätigkeit eher beendet werden, ist die vereinbarte Zeit zu vergüten, unbeschadet des Rechts von Pauline Meyer-Beer, in dieser Zeit weitere Einkünfte zu erzielen, es sei denn, es ist im Einzelfall etwas anderes schriftlich geregelt.
            </p>
            <p>
              3.2 Wird der Auftrag aus Gründen, die nicht von Pauline Meyer-Beer verschuldet sind, ganz oder teilweise vor dem Einsatz storniert, ist das vereinbarte Honorar wie folgt zu zahlen:
              Bei Stornierung bis 14 Tage vor dem Einsatz werden 50% des vereinbarten Honorars fällig, bei Stornierung bis sieben Tage vor dem Einsatz werden 100% des vereinbarten Honorars fällig.
              Pauline Meyer-Beer hat außerdem Anspruch auf Erstattung der ihr nachweislich entstanden Kosten (z. B. Fahrkarte für den ÖPNV&#8200;/&#8200;die DB). Fahrtkosten in Höhe der Autokilometer werden nur dann in Rechnung gestellt, wenn diese tatsächlich zurückgelegt worden sind.
            </p>
            <h4>4. Honorar</h4>
            <p>
              4.1 Gebärdensprachdolmetscher*innen sind in der Gestaltung ihres Honorars frei.
            </p>
            <p>
              4.2 Ausgenommen hiervon sind Dolmetscheinsätze, deren Vergütung aufgrund gesetzlicher Regelungen (z. B. dem Justizvergütungs- und -entschädigungsgesetz (JVEG) in seiner jeweils gültigen Fassung) erfolgen muss. Dies gilt auch für die Fahrtkosten.
            </p>
            <h4>5. Zahlung des Honorars</h4>
            <p>
              5.1 Das im Vertrag vereinbarte Honorar ist innerhalb von 14 Werktagen nach Rechnungserhalt unbar und ohne Abzug auf das Konto von Pauline Meyer-Beer unter Angabe der Rechnungsnummer zu zahlen.
            </p>
            <p>
              5.2 Für jede Mahnung werden Mahngebühren in Höhe von 5 Euro berechnet. Verzugszinsen werden mit 10% p. a. berechnet, wobei es Pauline Meyer-Beer freisteht, im Einzelfall einen höheren Verzugsschaden nachzuweisen und auf rechtlichem Wege einzufordern.
            </p>
            <h4>6. Verschwiegenheitspflicht</h4>
            <p>
              Pauline Meyer-Beer verpflichtet sich, während der Dauer des Dienstverhältnisses und auch nach dessen Beendigung über alle Betriebs- und Geschäftsgeheimnisse der AG Stillschweigen zu bewahren. Aufgrund des Berufes unterliegt Pauline Meyer-Beer der Berufs- und Ehrenordnung und somit einer Schweigepflicht und sieht sich ihren Kund*innen gegenüber verpflichtet, alle Informationen stets streng vertraulich zu behandeln. Dies beinhaltet mündlich übertragene Informationen, aber vor allem auch den Umgang mit überlassenen Materialien zur Vorbereitung der Einsätze (Redeskripte, Präsentationen etc.).
            </p>
            <h4>7. Leistungsumfang</h4>
            <p>
              7.1 Die Parteien sind bemüht, nach bestem Wissen und Gewissen den*die Vertragspartner*in bei der Erbringung der jeweiligen Verpflichtung durch Überlassen von Informationen, Auskünften oder Erfahrungen zu unterstützen, um einen reibungslosen und effizienten Arbeitsablauf für beide Parteien zu gewährleisten.
            </p>
            <p>
              7.2 Die Dolmetschleistung, die Pauline Meyer-Beer erbringt, besteht darin, gesprochene deutsche Sprache in Deutscher Gebärdensprache (DGS) oder nach Absprache in Lautsprachbegleitenden Gebärden (LBG) oder Lautsprachunterstützenden Gebärden (LUG) in der Regel simultan wiederzugeben oder umgekehrt einen in DGS, LBG oder LUG dargebotenen Text in deutscher Lautsprache in der Regel simultan wiederzugeben.
            </p>
            <h4>8. Arbeitsbedingungen</h4>
            <p>
              8.1 Zur Erbringung einer adäquaten Dolmetsch-Leistung sind gute visuelle und akustische Gegebenheiten am Einsatzort für Pauline Meyer-Beer unbedingt erforderlich. Hierfür tragen die AG Sorge und stellen, wenn nötig, Headsets oder geeignete Lautsprecher (Audiomonitore) zur Verfügung bzw. bei Präsentationen auf Großbildleinwand Laptops&#8200;/&#8200;Monitore, um die Präsentation verfolgen zu können. (Gebärdensprachdolmetscher*innen arbeiten üblicherweise dem Publikum zugewandt und haben die Präsentation im Rücken. Da die Präsentation auch Inhalt der Dolmetsch-Leistung ist, wird dieser Monitor benötigt.) Es obliegt weiterhin den AG, für das reibungslose Dolmetschen von der Gebärdensprache in die Lautsprache zu sorgen, indem sie geeignete Mikrofone bereitstellen. 
              Für eine optimale Ausleuchtung des Standortes der Dolmetscher*innen ist durch die AG zu sorgen.
            </p>
            <p>
              8.2 Musikalische und andere künstlerische Darbietungen (Lieder, Lyrik, Improvisationen, Theater und Ähnliches) werden nur nach Absprache und vorbereitet gedolmetscht.
            </p>
            <p>
              8.3 Vorbereitung ist für ein optimales Dolmetschprodukt unabdingbar. Dolmetscher*innen sind aufgrund Ihrer Berufs- und Ehrenordnung verpflichtet, stets optimal vorbereitet in die entsprechenden Situationen zu gehen. Die AG haben Pauline Meyer-Beer daher im eigenen Interesse rechtzeitig, spätestens vier Tage vor dem jeweiligen Einsatz, entsprechende Informationen zukommen zu lassen. Diese Informationen bzw. dieses Material wird selbstverständlich höchstvertraulich behandelt.
            </p>
            <p>
              8.4 Pausenzeiten werden, soweit nichts anderes schriftlich festgehalten wurde, wie folgt eingerichtet:
            </p>
            <ul className="bullet-list">  
              <li>nach einer Einsatzzeit von 1 Stunde: mind. 10 Minuten Pause</li>
              <li>nach einer Einsatzzeit von 4 Stunden: mind. 45 Minuten Pause</li>
            </ul>
            <h4>9. Urheberrecht</h4>
            <p>
              Das Dolmetschprodukt ist ausschließlich zur Visualisierung&#8200;/&#8200;Anhörung in dem Moment und an jenem Ort bestimmt, in dem und an dem sie erbracht wird. Eine Übertragung auf zum Beispiel Leinwände außerhalb des Raums oder im Internet sowie die Aufzeichnung der Dolmetschleistung  bedarf der vorherigen Zustimmung durch Pauline Meyer-Beer. Für die Abtretung der Nutzungsrechte wird ein zusätzliches Honorar berechnet, dessen Höhe sich nach Art der Nutzung richtet. 
              Die Urheberrechte bleiben Pauline Meyer-Beer vorbehalten. Die AG haften für unbefugte Aufnahmen durch Dritte.
            </p>
            <h4>10. Rücktritt vom Vertrag</h4>
            <p>
              10.1 Ist der Auftragnehmerin die Ausführung des Vertrages aufgrund falscher Angaben bei der Auftragserteilung oder aus anderen berufsethischen Gründen nicht zuzumuten, so ist sie berechtigt, von dem Vertrag zurückzutreten, ohne dass die Verpflichtung seitens der AG zur Zahlung des vereinbarten Honorars entfällt.
            </p>
            <p>
              10.2 Sollte die Auftragnehmerin aus schwerwiegenden, nachvollziehbaren Gründen an der Ausführung des Auftrags gehindert sein, verpflichtet sie sich, sich um adäquaten Ersatz in Form anderer qualifizierter Fachkolleg*innen zu bemühen. Eine weitere Verpflichtung vonseiten der Auftragnehmerin besteht nicht.
            </p>
            <h4>11. Gerichtsstand</h4>
            <p>
              Der Gerichtsstand ist Berlin.
            </p>
            <h4>12. Salvatorische Klausel</h4>
            <p>
              Sollte eine oder mehrere der vorstehenden Bestimmungen ungültig sein, so soll die Wirksamkeit der übrigen Bestimmungen hiervon nicht berührt werden. Dies gilt auch, wenn innerhalb einer Regelung ein Teil unwirksam, ein anderer Teil aber wirksam ist. Die jeweils unwirksame Bestimmung soll von den Parteien durch eine Regelung ersetzt werden, die den wirtschaftlichen Interessen der Vertragsparteien am nächsten kommt und die den übrigen vertraglichen Vereinbarungen nicht zuwiderläuft.
            </p>
          </div>
        </div>
      </div>
      <Contact
        backgroundColor="white"
        buttonType="inquiry"
        />
    </div>
  );
}

export default AGB;
