import { Link } from "react-router-dom";

const Nav = ({showNav, setNavigationVisible, scrollToBottom}) => {
  return (
    <>
      <nav className={showNav ? "nav" : "nav-hidden"}>
        <ul>
          <li>
            <Link to="/" onClick={() => setNavigationVisible(false)}>Dolmetschen</Link>
          </li>
          <li>
            <Link to="/ueber_mich" onClick={() => setNavigationVisible(false)}>Über mich</Link>
          </li>
          <li onClick={() => {
              setNavigationVisible(false);
              scrollToBottom();}
            }>
            Kontaktdaten
          </li>
          <li>
            <Link to="/anfrage" onClick={() => setNavigationVisible(false)}>Anfragen</Link>
          </li>
        </ul>
      </nav>
    </>
  );
}
  
export default Nav;
  