
import { ReactComponent as Haken } from './../assests/Haken.svg';

const SendButton = ({ sentSuccessfully, onSend }) => {
  return (
    <div className='send-button' onClick={e => onSend(e)}>
      {
        sentSuccessfully
          ? <Haken />
          : <span>Abschicken</span>
      }
      <input className={`button-circle button-${sentSuccessfully ? "green" : "orange"}`} type="submit" value={sentSuccessfully ? "" : "Abschicken"} />
    </div>
  );
}
  
export default SendButton;
