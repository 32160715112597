import React, { useState, useEffect } from 'react';
import Contact from '../components/contact';

const DataPrivacyStatement = () => {

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
  })

  const isMobile = () => {
    return screenWidth <= 1100;
  }

  return (
    <div className="body">
      <div className="home-row">
        <div className="row-flex-start">
          <div className="col-big col-big-margin-top-and-bottom">
            <h2>
              Datenschutzerklärung
              {
                !isMobile() &&
                  <><br /><br /></>
              }
            </h2>
            
            <h4>Benennung der verantwortlichen Stelle</h4>
            <p>
              Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:
            </p>
            <p>
              Pauline Carla Meyer-Beer
              <br />
              Warthestraße 30
              <br />
              12051 Berlin
            </p>
            <p className='p-without-margin-bottom'>
              Die verantwortliche Stelle entscheidet allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.B. Namen, Kontaktdaten o. Ä.).
            </p>
          </div>
          <div className="col-big">
            <h2>Allgemeiner Hinweis und Pflichtinformationen</h2>
            <h4>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h4>
            <p>
              Nur mit Ihrer ausdrücklichen Einwilligung sind einige Vorgänge der Datenverarbeitung möglich. Ein Widerruf Ihrer bereits erteilten Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine formlose Mitteilung per E-Mail. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
            </p>
            <h4>Recht auf Beschwerde bei der zuständigen Aufsichtsbehörde</h4>
            <p>
              Als Betroffene*r steht Ihnen im Falle eines datenschutzrechtlichen Verstoßes ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. Zuständige Aufsichtsbehörde bezüglich datenschutzrechtlicher Fragen ist der*die Landesdatenschutzbeauftragte des Bundeslandes, in dem sich der Sitz meines Unternehmens befindet. Der folgende Link stellt eine Liste der Datenschutzbeauftragten sowie deren Kontaktdaten bereit:
            </p>
            <p>
            <a href="https://www.bfdi.bund.de/DE/Service/Anschriften/Laender/Laender-node.html" target="_blank" rel="noopener noreferrer">https://www.bfdi.bund.de/DE/Service/Anschriften/Laender/Laender-node.html</a>
            </p>
            <h4>Recht auf Datenübertragbarkeit</h4>
            <p>
              Ihnen steht das Recht zu, Daten, die ich auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags automatisiert verarbeite, an sich oder an Dritte aushändigen zu lassen. Die Bereitstellung erfolgt in einem maschinenlesbaren Format. Sofern Sie die direkte Übertragung der Daten an eine*n andere*n Verantwortliche*n verlangen, erfolgt dies nur, soweit es technisch machbar ist.
            </p>
            <h4>
              Recht auf Auskunft, Berichtigung,
              <br />
              Sperrung, Löschung
            </h4>
            <p>
              Sie haben jederzeit im Rahmen der geltenden gesetzlichen Bestimmungen das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, Herkunft der Daten, deren Empfänger*innen und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten. Diesbezüglich und auch zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit über die im Impressum aufgeführten Kontaktmöglichkeiten an mich wenden.
            </p>
            <h4>SSL- bzw. TLS-Verschlüsselung</h4>
            <p>
              Aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, die Sie an mich als Seitenbetreiberin senden, nutzt meine Webseite eine SSL-bzw. TLS-Verschlüsselung. Damit sind Daten, die Sie über diese Webseite übermitteln, für Dritte nicht mitlesbar. Sie erkennen eine verschlüsselte Verbindung an der „https://“ Adresszeile Ihres Browsers und am Schloss-Symbol in der Browserzeile.
            </p>
            <h4>Server-Log-Dateien</h4>
            <p>
              In Server-Log-Dateien erhebt und speichert der Provider der Webseite automatisch Informationen, die Ihr Browser automatisch an mich übermittelt. Dies sind:
            </p>
            <ul className="bullet-list">  
              <li>Besuchte Seite auf meiner Domain</li>
              <li>Datum und Uhrzeit der Serveranfrage</li>
              <li>Browsertyp und Browserversion</li>
              <li>Verwendetes Betriebssystem</li>
              <li>Referrer URL</li>
              <li>Hostname des zugreifenden Rechners</li>
              <li>IP-Adresse</li>
            </ul>
            <p>
              Es findet keine Zusammenführung dieser Daten mit anderen Datenquellen statt. Grundlage der Datenverarbeitung bildet Art. 6 Abs. 1 lit. b DSGVO, der die Verarbeitung von Daten zur Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen gestattet.
            </p>
            <h4>Kontaktformular</h4>
            <p>
              Per Kontaktformular übermittelte Daten werden einschließlich Ihrer Kontaktdaten gespeichert, um Ihre Anfrage bearbeiten zu können oder um für Anschlussfragen bereitzustehen. Eine Weitergabe dieser Daten findet ohne Ihre Einwilligung nicht statt. Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt ausschließlich auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Ein Widerruf Ihrer bereits erteilten Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine formlose Mitteilung per E-Mail. 
              <br />
              Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt. Über das Kontaktformular übermittelte Daten verbleiben bei mir, bis Sie mich zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder keine Notwendigkeit der Datenspeicherung mehr besteht. Zwingende gesetzliche Bestimmungen – insbesondere Aufbewahrungsfristen – bleiben unberührt.
            </p>
            <h4>Quelle</h4> 
            <p>
              Datenschutz-Konfigurator von<br /><a href="mein-datenschutzbeauftragter.de" target="_blank" rel="noopener noreferrer">mein-datenschutzbeauftragter.de</a>
            </p>           
          </div>
        </div>
      </div>
      <Contact
        backgroundColor="white"
        buttonType="inquiry"
        />
    </div>
  );
}

export default DataPrivacyStatement;
