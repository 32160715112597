import React, { useState, useRef, useEffect } from 'react';
import emailjs from '@emailjs/browser';
import InquireBody from "../components/inquire";
import Contact from "../components/contact";
import emailkeys from '../emailkeys';

// https://medium.com/@patienceadajah/how-to-send-emails-from-a-react-application-without-a-backend-server-1dd8718ceedd

const Inquire = () => {
  const formRef = useRef();

  const [isDirty, setIsDirty] = useState(false);
  const [showHint, setShowHint] = useState(false);
  const [showRequestOk, setShowRequestOk] = useState(false);
  const [showRequestError, setShowRequestError] = useState(false);
  const [checkedDataPrivacyStatement, setChecked] = useState(false);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds(seconds => seconds + 1);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const toggleCheckbox = (e) => {
    e.preventDefault();
    setChecked(!checkedDataPrivacyStatement);
  };

  const onSend = (e) => {
    e.preventDefault();
    if (isSpam()) {
      resetForm();
    } else {
      if (validateRequiredInputs()) {
        setShowHint(false);
        sendMail();
      } else {
        setIsDirty(true);
        setShowHint(true);
      }
    }
  }
  
  const isSpam = () => {
    var password = document.getElementById("input-password").value;
    // input-password is a hidden field that is only filled by bots
    // a normal person needs more than 5 seconds to fill in the form
    return password.length > 0 && seconds < 5;
  }

  const validateMail = (mail) => {
    const res = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return res.test(String(mail).toLowerCase());
  }
  
  const isNotAnEmptyString = (value) => !!value && value.trim().length > 0;

  const validateRequiredInputs = () => {
    var name = document.getElementById("input-name").value;
    var mail = document.getElementById("input-mail").value;
    var subject = document.getElementById("input-subject").value;
    return validateMail(mail) && isNotAnEmptyString(name) && isNotAnEmptyString(subject) && checkedDataPrivacyStatement;
  }

  const resetForm = () => {
    formRef.current.reset();
    setChecked(false);
  }

  const sendMail = () => {
    emailjs.sendForm(emailkeys.SERVICE_ID, emailkeys.TEMPLATE_ID, formRef.current, emailkeys.PUBLIK_KEY)
      .then((result) => {
          setShowRequestOk(true);
          setIsDirty(false);
          console.log(result.text);
          resetForm();
      }, (error) => {
          setShowRequestError(true);
          console.log(error.text);
      });
  }

  return (
    <div className="body">
      <div className="home-row">
        <h1 className="inquire">
          Sie können mir eine E-Mail schreiben an&nbsp;
          <a href="&#x6d;&#x61;&#x69;&#x6c;&#x74;&#x6f;&#x3a;&#x70;&#x6f;&#x73;&#x74;&#x40;&#x6d;&#x65;&#x79;&#x65;&#x72;&#x2d;&#x62;&#x65;&#x65;&#x72;&#x2e;&#x64;&#x65;" className='itc-century-link'>
            &#x70;&#x6f;&#x73;&#x74;&#x40;&#x6d;&#x65;&#x79;&#x65;&#x72;&#x2d;&#x62;&#x65;&#x65;&#x72;&#x2e;&#x64;&#x65;</a>
          &nbsp; oder einfach direkt über das
          Kontakt-Formular eine
          Dolmetsch-Anfrage schicken.
          Ich freue mich auf Ihre Nachricht!
        </h1>
      </div>
      <form ref={formRef} onSubmit={(e) => onSend(e)} >
        <div className="home-row">
          <input type="text" name="password" id="input-password" className="password-field" tabIndex="-1" autoComplete="off" />
          <InquireBody
            toggleCheckbox={toggleCheckbox}
            dataPrivacyChecked={checkedDataPrivacyStatement}
            showHint={showHint}
            setShowHint={setShowHint}
            showRequestOk={showRequestOk}
            setShowRequestOk={setShowRequestOk}
            showRequestError={showRequestError}
            setShowRequestError={setShowRequestError}
            isDirty={isDirty}
          />
        </div>
        <Contact
          onSend={onSend}
          backgroundColor="white"
          buttonType="send"
          buttonBackgroundColor={showRequestOk ? "green" : "orange"}
          />
        </form>
    </div>
  );
}

export default Inquire;
  