import React, { useState, useEffect } from 'react';
import Carousel, { CarouselItem } from "../components/carousel";
import Contact from '../components/contact';
import InquiryButton from '../elements/inquire-button';
import image1 from './../images/interpreting_1.jpg';
import image2 from './../images/interpreting_2.jpg';
import image3 from './../images/interpreting_3.jpg';
import carouselImage1 from './../images/interpreting_carousel_1.jpg';
import carouselImage2 from './../images/interpreting_carousel_2.jpg';
import carouselImage3 from './../images/interpreting_carousel_3.jpg';
import carouselImage4 from './../images/interpreting_carousel_4.jpg';
import carouselImage5 from './../images/interpreting_carousel_5.jpg';
import pdf from './../files/Muster-Antrag_auf_Soziale_Teilhabe.pdf';

const Home = () => {

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
  })

  const isMobile = () => {
    return screenWidth <= 1100;
  }

  return (
    <div className="body">
      <div className="background-image-home" />
      <div className="home-header home-row">
          <h1>
            Dolmetschen zwischen<br />
            Deutsch und Deutscher<br />
            Gebärdensprache
          </h1>
          <h3>
            in Berlin, Brandenburg<br />
            und bundesweit
          </h3>
          <InquiryButton />
        </div>
      <div className="background-main-1">
        <div className="row row-standard row-reverse-on-small-screens">
          <div className="col-big col-big-margin-top">
            <h2>Dolmetschtätigkeit</h2>
            <p>
              Ich dolmetsche in unterschiedlichen Bereichen des alltäglichen Lebens in Kommunikations-Situationen zwischen tauben&#8200;/&#8200;gehörlosen&#8200;/&#8200;schwerhörigen und hörenden Menschen. 
            </p>
            <p>
              Meine Arbeitssprachen sind dabei Deutsch und Deutsche Gebärdensprache (DGS). Neben DGS biete ich auch Lautsprachbegleitende Gebärden (LBG) und Lautsprachunterstützende Gebärden (LUG) an.
            </p>
            <p>
              Mein Ziel ist es, für alle Beteiligten eine leichte(re), angenehme(re) und gut funktionierende Kommunikation miteinander zu ermöglichen.
            </p>
            <p className='p-without-margin-bottom'>
              Gesehen, gehört und verstanden zu werden sind urmenschliche Grundbedürfnisse. Zu deren Erfüllung einen Teil beizutragen, das ist meine ganz persönliche Motivation.
            </p>
          </div>
          <div className="col-small">
            <img src={image1} alt="image1"/>
          </div>
        </div>
      </div>
      <div className="background-main-2">
      <div className="row row-slider-top">
        <div className="col-big">
          <h2>Ich dolmetsche für Sie und Euch in den Bereichen …</h2>
        </div>
        {
          !isMobile() &&
            <InquiryButton />
        }
      </div>
        <Carousel>
          <CarouselItem>
            <div className="row row-slider">
              <div className="col-big">
                <img src={carouselImage1} alt="image2" />
              </div>
              <div className="col-small col-small-slider">
                {
                  isMobile() &&
                    <InquiryButton />
                }
                <h2>… Bildung</h2>
                <ul>
                  <li>Ausbildung + Studium</li>
                  <li>Elternabende in Schulen und Kitas</li>
                  <li>Fort- und Weiterbildungen</li>
                  <li>Seminare, Schulungen, Workshops</li>
                  <li>Studienseminar&#8200;/&#8200;Vorbereitungsdienst für angehende Lehrkräfte</li>
                </ul>
              </div>
            </div>
          </CarouselItem>
          <CarouselItem>
          <div className="row row-slider">
              <div className="col-big">
                <img src={carouselImage2} alt="image7" />
              </div>
              <div className="col-small col-small-slider">
                {
                  isMobile() &&
                    <InquiryButton />
                }
                <h2>… Gesundheit</h2>
                <ul>
                  <li>Ärztliche Praxen</li>
                  <li>Geburtshäuser</li>
                  <li>Krankenhäuser</li>
                  <li>Kurkliniken</li>
                  <li>Rehabilitationseinrichtungen</li>
                  <li>Tageskliniken</li>
                </ul>
              </div>
            </div>
          </CarouselItem>
          <CarouselItem>
          <div className="row row-slider">
              <div className="col-big">
                <img src={carouselImage3} alt="image9" />
              </div>
              <div className="col-small col-small-slider">
                {
                  isMobile() &&
                    <InquiryButton />
                }
                <h2>… Arbeitsleben</h2>
                <ul>
                  <li>Teambesprechungen</li>
                  <li>Betriebsversammlungen</li>
                  <li>Fort- und Weiterbildungen</li>
                  <li>Seminare, Workshops, Schulungen</li>
                </ul>
              </div>
            </div>
          </CarouselItem>
          <CarouselItem>
          <div className="row row-slider">
              <div className="col-big">
                <img src={carouselImage4} alt="image11" />
              </div>
              <div className="col-small col-small-slider">
                {
                  isMobile() &&
                    <InquiryButton />
                }
                <h2 className='small-text-box'>… Ämter&#8200;/&#8200;Behörden</h2>
                <ul>
                  <li>Agentur für Arbeit</li>
                  <li>Bezirksamt</li>
                  <li>Gesundheitsamt</li>
                  <li>Jugendamt</li>
                  <li>Jobcenter</li>
                </ul>
              </div>
            </div>
          </CarouselItem>
          <CarouselItem>
          <div className="row row-slider">
              <div className="col-big">
                <img src={carouselImage5} alt="image8" />
              </div>
              <div className="col-small col-small-slider">
                {
                  isMobile() &&
                    <InquiryButton />
                }
                <h2>… Privatleben</h2>
                <ul>
                  <li>Beratungsstellen</li>
                  <li>Hochzeiten und andere Feierlichkeiten</li>
                  <li>Inklusive Kinder- und Jugendfreizeiten</li>
                  <li>Stadt- und Naturführungen</li>
                  <li>Weltliche&#8200;/&#8200;säkulare Bestattungen und Trauerfeiern</li>
                </ul>
              </div>
            </div>
          </CarouselItem>
        </Carousel>
      </div>
      <div className="background-main-1">
        <div className="row row-standard row-reverse-on-small-screens">
          <div className="col-big col-big-margin-top">
            <h2>Kostenübernahme im privaten Bereich + Muster-Antrag</h2>
            <p>
              Während in den Bereichen Bildung, Gesundheit, Arbeitsleben und Ämter&#8200;/&#8200;Behörden 
              jeweils bestimmte Kostenträger das Dolmetschen bezahlen, bestehen im privaten Bereich noch 
              große Lücken. 
            </p>
            <p className='p-without-margin-bottom'>
              Dennoch gibt es die Möglichkeit der Kostenübernahme oder des Zuschusses zur Finanzierung von Dolmetschleistungen für die soziale Teilhabe. 
              Voraussetzung dafür ist, dass es sich um eine „Verständigung mit der Umwelt aus besonderem Anlass“ handelt (§ 82 SGB IX). 
            </p>
          </div>
          <div className="col-small">
            <img src={image2} alt="image10"/>
          </div>
        </div>
        <div className="row row-standard-no-padding-top">
        <div className="col-small">
            <img src={image3} alt="image3"/>
          </div>
          <div className="col-big col-big-margin-top">
            <p>
              Besondere Anlässe können beispielsweise Hochzeiten, Trauerfeiern, Termine bei Beratungsstellen 
              und andere nicht alltägliche Situationen sein.
            </p>
            <p className='p-without-margin-bottom'>
              Die Kostenübernahme&#8200;/&#8200;die Höhe des Zuschusses 
              ist abhängig vom Einkommen und Vermögen und muss vor dem Termin beim Sozialamt&#8200;/&#8200;Amt für 
              Eingliederungshilfe beantragt werden. Sie können dafür diesen Muster-Antrag verwenden: 
            </p>
            <p>
              <a href={pdf} rel="noopener noreferrer" target="_blank">Muster-Antrag auf Soziale Teilhabe laden</a> 
            </p>
          </div>
        </div>
      </div>
      <Contact
        backgroundColor="blue"
        buttonType="inquiry"
        />
    </div>
  );
}

export default Home;
