import React from 'react';
import Contact from '../components/contact';

const Imprint = () => {

  return (
    <div className="body">
      <div className="home-row">
        <div className="row-flex-start">
          <div className="col-big col-big-margin-top-and-bottom">
            <h2>Impressum</h2>
            <p>
              Angaben gemäß § 5 TMG
              <br /><br />
              Pauline Carla Meyer-Beer 
              <br />
              Warthestraße 30
              <br />
              12051 Berlin
              <br /><br />
              <b>Vertreten durch</b>
              <br />
              Pauline Carla Meyer-Beer
              <br /><br />
              <b>Kontakt</b>
              <br />
              <a href="tel:+491773026877">+49 (0)177 302 6877</a>
              <br />
              <a href="&#x6d;&#x61;&#x69;&#x6c;&#x74;&#x6f;&#x3a;&#x70;&#x6f;&#x73;&#x74;&#x40;&#x6d;&#x65;&#x79;&#x65;&#x72;&#x2d;&#x62;&#x65;&#x65;&#x72;&#x2e;&#x64;&#x65;">
              &#x70;&#x6f;&#x73;&#x74;&#x40;&#x6d;&#x65;&#x79;&#x65;&#x72;&#x2d;&#x62;&#x65;&#x65;&#x72;&#x2e;&#x64;&#x65;</a>
            </p>
          </div>
          <div className="col-big">
            <h2>Haftungsausschluss</h2>
            <h4>Haftung für Inhalte</h4>
            <p>
              Die Inhalte meiner Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte kann ich jedoch keine Gewähr übernehmen. Als Diensteanbieterin bin ich gemäß § 7 Abs. 1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG bin ich als Diensteanbieterin jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werde ich diese Inhalte umgehend entfernen.
            </p>
            <h4>Haftung für Links</h4>
            <p>
              Mein Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte ich keinen Einfluss habe. Deshalb kann ich für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der*die jeweilige Anbieter*in oder Betreiber*in der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werde ich derartige Links umgehend entfernen.
            </p>
            <h4>Urheberrecht</h4>
            <p>
              Die durch die Seitenbetreiberin erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des*der jeweiligen Autor*in bzw. Ersteller*in. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht von der Betreiberin erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitte ich um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werde ich derartige Inhalte umgehend entfernen.
            </p>
            <h4>Design &amp; Gestaltung</h4>
            <h6>Art Direction</h6>
            <p>
              Diana Tejada, Atelier Tejada –
              <br />
              freiberufliche Kommunikationsdesignerin 
              <br />
              &amp; Illustratorin
            </p>
            <h6>Fotografie</h6>
            <p>
              Vivien Staff – freischaffende Fotografin,
              <br />
              Illustatorin &amp; Grafikdesignerin
            <p/>
            <h6>Stock-Fotografie</h6>
            <p>
              Green Chameleon – Unsplash
              <br />
              Ammar Elamir – Unsplash
              <br />
              Christin Hume – Unsplash
              <br />
              Markus Spiske – Unsplash
              <br />
              Annie Spratt – Unsplash
              <br />
              Diane Helentjaris – Unsplash
            </p>
            <h6>Programmierung</h6>
            <p>
              Sonja Nürenberg – Programmiererin &amp;
              <br />
              freiberufliche Yoga-Lehrerin
            </p>
            <h4>Datenschutz</h4>
            <p>
              Die Nutzung meiner Webseite ist in der Regel ohne Angabe personenbezogener Daten möglich. Soweit auf meinen Seiten personenbezogene Daten (beispielsweise Name, Anschrift oder E-Mail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben.
            </p>
            <p/>
            Ich weise darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.
            </p>
            <p>
            Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten durch Dritte zur Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit ausdrücklich widersprochen. Die Betreiberin der Seiten behält sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-Mails, vor. 
            </p>
            <h4>Impressum</h4>
            <p>
              Impressum vom <a href="https://www.impressum-generator.de" target="_blank" rel="noopener noreferrer">Impressum Generator</a> der Kanzlei Hasselbach, Rechtsanwälte für Arbeitsrecht und Familienrecht.
            </p>
          </div>
        </div>
      </div>
      <Contact
        backgroundColor="white"
        buttonType="inquiry"
        />
    </div>
  );
}

export default Imprint;
