import TextField from "./components/TextField";
import { ReactComponent as NavSchliessen } from './../../assests/NavSchliessen.svg';
import CheckboxUserAgreement from "./components/CheckboxUserAgreement";

const InquireBody = ({
    isDirty,
    showHint,
    setShowHint,
    showRequestOk,
    setShowRequestOk,
    showRequestError,
    setShowRequestError,
    toggleCheckbox,
    dataPrivacyChecked
}) => {
    return (
        <div className="row-flex-start">
            <div className="col-big">
                <h2>Kontakt-Formular</h2>
                <p>
                    Sollte aufgrund der Dauer des Einsatzes eine Doppelbesetzung notwendig sein, kümmere ich mich gern um eine*n zweite*n Dolmetscher*in.
                </p>
                <CheckboxUserAgreement
                    id="user-agreement-checkbox"
                    onToggleCheckbox={toggleCheckbox}
                    checked={dataPrivacyChecked}
                    isDirty={isDirty}
                    />
                <p>
                    <span className="text-color-required">*</span>erforderliche Daten
                </p>
            </div>

            <div className="col-big">
               
                <TextField 
                    id="name"
                    caption="Ihr Name"
                    placeholder="Vorname Nachname"
                    isDirty={isDirty}
                    required
                    />
                <TextField 
                    id="mail"
                    caption="Ihre E-Mail-Adresse"
                    placeholder="E-Mail-Adresse"
                    isDirty={isDirty}
                    required
                    />
                <TextField 
                    id="phone"
                    caption="Ihre Telefonnummer"
                    placeholder="Telefonnummer"
                    />
                <TextField 
                    id="day"
                    caption="An welchem Tag soll gedolmetscht werden?"
                    placeholder="Einsatz-Datum"
                    />
                <TextField 
                    id="startTime"
                    caption="Um wie viel Uhr beginnt der Einsatz?"
                    placeholder="Einsatz-Beginn"
                    />
                <TextField 
                    id="endTime"
                    caption="Um wie viel Uhr endet der Einsatz?"
                    placeholder="Einsatz-Ende"
                    />
                <TextField 
                    id="subject"
                    caption="Worum geht es?"
                    placeholder="Fortbildung, Elternabend, Feier etc."
                    isDirty={isDirty}
                    required
                    />
                <TextField 
                    id="costBearer"
                    caption="Ist die Kosten-Übernahme schon geklärt? Wenn ja, wer trägt die Kosten?"
                    placeholder="Kostenträger"
                    />
                <TextField 
                    id="person"
                    caption="Soll für Sie gedolmetscht werden oder für eine andere Person?"
                    placeholder="Name Leistungs-Empfänger*in"
                    />
                <TextField 
                    id="message"
                    caption="Möchten Sie mir noch etwas mitteilen?"
                    placeholder="Ihre Nachricht"
                    textarea
                    />
                {
                    showHint && (
                        <div className="inquire-hint">
                            <NavSchliessen  onClick={() => setShowHint(false)} className="checked absolute-right-top"/>
                            <p>
                                <b>Fehler beim Ausfüllen des Formulars!</b><br />
                                Bitte akzeptieren Sie die Bedingungen und überprüfen Sie Ihre Eingaben, um fortzufahren. 
                            </p>
                            <p className="p-without-margin-bottom">
                                Sie erklären sich damit einverstanden, dass Ihre Daten zur Bearbeitung Ihres Anliegens verwendet werden. Weitere Informationen und Widerrufshinweise finden Sie in der <span>Datenschutzerklärung</span>
                            </p>
                        </div>
                    )
                }
                {
                    showRequestError && (
                        <div className="inquire-hint">
                            <NavSchliessen  onClick={() => setShowRequestError(false)} className="checked absolute-right-top"/>
                            <p className="p-without-margin-bottom">
                                <b>Beim Absenden des Formulars ist ein Fehler aufgetreten.</b><br />
                                Bitte überprüfen Sie Ihre Internetverbindung und versuchen es erneut.
                            </p>
                        </div>
                    )
                }
                {
                    showRequestOk && (
                        <div className="inquire-hint background-color-green">
                            <NavSchliessen  onClick={() => setShowRequestOk(false)} className="checked absolute-right-top"/>
                            <p>
                                <b>Vielen Dank für Ihre Anfrage!</b><br />
                                Ich werde Ihre Anfrage sobald wie möglich bearbeiten und mich via Mail bei Ihnen melden.
                            </p>
                            <p>
                                Ich freue mich auf unsere Zusammenarbeit!
                            </p>
                            <p className="p-without-margin-bottom">
                                Liebe Grüße,<br />
                                Pauline Carla Meyer-Beer
                            </p>
                        </div>
                    )
                }
            </div>
    </div>
  );
}
  
export default InquireBody;
  