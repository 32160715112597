import React from 'react';
import { Link } from 'react-router-dom';
import Contact from '../components/contact';

const InformationObligation = () => {

  return (
    <div className="body">
      <div className="home-row">
        <div className="row-flex-start">
          <div className="col-big col-big-margin-top-and-bottom">
            <h2>Inhalt</h2>
            <ol className='content-list'>
              <li>Name &amp; Kontaktdaten verantwortlichen Stelle</li>
              <li>Kontaktdaten des*der Datenschutzbeauftragten</li>
              <li>Erhebung und Speicherung personenbezogener Daten; Art, Zweck und Verwendung</li>
              <li>Weitergabe von Daten an Dritte</li>
              <li>Ihre Rechte als betroffene Person</li>
              <li>Ihr Recht auf Widerspruch</li>
              <li>Datenverarbeitung über meine Webseite</li>
            </ol>
          </div>
          <div className="col-big">
            <h2>Informationspflicht</h2>
            <h4>1. Name &amp; Kontaktdaten der verantwortlichen Stelle</h4>
            <p>
              Diese Datenschutzhinweise gelten für mich,
              <br /><br />
              Pauline Carla Meyer-Beer
              <br />
              Warthestraße 30
              <br />
              12051 Berlin
              <br /><br />
              <a href="tel:+491773026877">+49 (0)177 302 6877</a>
              <br />
              <a href="&#x6d;&#x61;&#x69;&#x6c;&#x74;&#x6f;&#x3a;&#x70;&#x6f;&#x73;&#x74;&#x40;&#x6d;&#x65;&#x79;&#x65;&#x72;&#x2d;&#x62;&#x65;&#x65;&#x72;&#x2e;&#x64;&#x65;">
              &#x70;&#x6f;&#x73;&#x74;&#x40;&#x6d;&#x65;&#x79;&#x65;&#x72;&#x2d;&#x62;&#x65;&#x65;&#x72;&#x2e;&#x64;&#x65;</a>
              <br />
              www.meyer-beer.de
              <br /><br />
              als verantwortliche Stelle.
            </p>
            <h4>2. Kontaktdaten des*der Datenschutzbeauftragten</h4>
            <p>
              Gesetzlich bin ich nicht verpflichtet, eine*n Datenschutzbeauftragte*n zu benennen, da ich Einzelunternehmerin bin. Dennoch halte ich mich an die Vorgaben der Datenschutzgesetze.
            </p>
            <h4>3. Erhebung und Speicherung personenbezogener</h4>
            <h4>Daten; Art, Zweck und Verwendung</h4>
            <p>
              Wenn Sie mich beauftragen, werden folgende Informationen von Ihnen erhoben:
            </p>
            <ul className="bullet-list">  
              <li>Anrede, Titel, Vorname, Nachname</li>
              <li>Anschrift</li>
              <li>E-Mail-Adresse</li>
              <li>Telefonnummer (Festnetz und&#8200;/&#8200;oder Mobilfunk)</li>
              <li>ggf. Kontodaten</li>
              <li>ggf. Geburtsdatum</li>
            </ul>
            <p>
              Außerdem werden alle Informationen erhoben, die für die Erfüllung des Vertrages&#8200;/&#8200;Auftrages mit Ihnen notwendig sind. Die Erhebung der personenbezogenen Daten erfolgt,
            </p>
            <ul className="bullet-list">  
              <li>um Sie als Kund*in identifizieren zu können;</li>
              <li>um Sie angemessen beraten zu können;</li>
              <li>um meine vertraglichen Pflichten Ihnen gegenüber erfüllen zu können;</li>
              <li>um meinen gesetzlichen Verpflichtungen nachkommen zu können;</li>
              <li>zur Korrespondenz mit Ihnen;</li>
              <li>zur Rechnungsstellung bzw. ggf. im Rahmen des Mahnwesens;</li>
              <li>zur Geltendmachung etwaiger Ansprüche gegen Sie.</li>
            </ul>
            <p>
              Die Verarbeitung der personenbezogenen Daten erfolgt anlässlich Ihrer Anfrage bei mir und ist zu den genannten Zwecken für die Bearbeitung Ihres Anliegens und für die Erfüllung von Verpflichtungen aus dem zugrundeliegenden Vertrag&#8200;/&#8200;Auftrag erforderlich. Die erhobenen personenbezogenen Daten werden bis zum Ablauf der gesetzlichen Aufbewahrungspflicht (sechs, acht oder zehn Jahre nach Ablauf des Kalenderjahres, in dem das Vertragsverhältnis beendet wurde) gespeichert und danach gelöscht. Dies gilt ausnahmsweise nicht, wenn ich aufgrund von steuer- oder handelsrechtlichen Aufbewahrungspflichten (gemäß HGB, StGB oder AO) zu einer längeren Speicherung verpflichtet bin oder wenn Sie in eine darüberhinausgehende Speicherung eingewilligt haben.
            </p>
            <h4>4. Weitergabe von Daten an Dritte</h4>
            <p>
              Eine Übermittlung Ihrer personenbezogenen Daten an Dritte findet grundsätzlich nicht statt. Ausnahmen hiervon gelten nur, soweit dies für die Abwicklung vom Vertrag&#8200;/&#8200;Auftrag mit Ihnen erforderlich ist. Hierzu zählt insbesondere die Weitergabe an von mir beauftragte Dienstleister*innen (sog. Auftragsverarbeiter*innen) oder sonstige Dritte, deren Tätigkeit für die Vertrags-&#8200;/&#8200;Auftragsdurchführung erforderlich ist. 
              Die weitergegebenen Daten dürfen von den Dritten ausschließlich zu den genannten Zwecken verwendet werden.
            </p>
            <h4>5. Ihre Rechte als betroffene Person</h4>
            <p>Ihnen als von der Datenverarbeitung betroffenen Person stehen verschiedene Rechte zu:</p>
            <h6>Widerrufsrecht</h6>
            <p>
              Von Ihnen erteilte Einwilligungen können Sie jederzeit mir gegenüber widerrufen. Die Datenverarbeitung, die auf der widerrufenen Einwilligung beruht, darf dann für die Zukunft nicht mehr fortgeführt werden.
            </p>
            <h6>Auskunftsrecht</h6>
            <p>
              Sie können Auskunft über Ihre von mir verarbeiteten personenbezogenen Daten verlangen.
              Dies gilt insbesondere für die Zwecke der Datenverarbeitungen, die Kategorien der personenbezogenen Daten, ggf. die Kategorien von Empfänger*innen, die Speicherdauer, ggf. die Herkunft Ihrer Daten.
            </p>
            <h6>Berichtigungsrecht</h6>
            <p>
              Sie können die Berichtigung unrichtiger oder 
              die Vervollständigung Ihrer bei mir gespeicherten personenbezogenen Daten verlangen.
            </p>
            <h6>Löschungsrecht</h6> 
            <p>
              Sie können die Löschung Ihrer bei mir gespeicherten personenbezogenen Daten verlangen, soweit deren Verarbeitung nicht zur Ausübung des Rechts auf freie Meinungsäußerung und Information, zur Erfüllung einer rechtlichen Verpflichtung, aus Gründen des öffentlichen Interesses oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist.
            </p>
            <h6>Recht auf Einschränkung der Verarbeitung</h6> 
            <p>
              Sie können die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten verlangen, soweit die Richtigkeit der Daten von Ihnen bestritten wird, die Verarbeitung unrechtmäßig ist, Sie aber deren Löschung ablehnen. Außerdem steht Ihnen dieses Recht zu, wenn ich die Daten nicht mehr benötige, Sie diese jedoch zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen. Darüber hinaus haben Sie dieses Recht, wenn Sie Widerspruch gegen die Verarbeitung Ihrer personenbezogenen Daten eingelegt haben.
            </p>
            <h6>Recht auf Datenübertragbarkeit</h6> 
            <p>
              Sie können verlangen, dass ich Ihnen Ihre personenbezogenen Daten, die Sie mir bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesebaren Format übermittle. Alternativ können Sie die direkte Übermittlung der von Ihnen mir bereitgestellten personenbezogenen Daten an eine*n andere*n Verantwortliche*n verlangen, soweit dies möglich ist.
            </p>
            <h6>Beschwerderecht</h6>
            <p>
              Sie können sich bei jeder Aufsichtsbehörde für den Datenschutz beschweren, z.B. wenn Sie der Ansicht sind, dass ich Ihre personenbezogenen Daten in unrechtmäßiger Weise verarbeite. Die für mich zuständige Aufsichtsbehörde ist:
            </p>
            <h6>Berliner Beauftragte für Datenschutz und Informationsfreiheit</h6>
            <p>
              Friedrichstr. 219
              <br />
              Besuchereingang: Puttkamerstr. 16–18 (5. Etage)
              <br />
              10969 Berlin
              <br /><br />    
              Telefon: 030 13 88 90
              <br />
              Telefax: 030 21 55 05 0
              <br />
              E-Mail: mailbox@datenschutz-berlin.de
            </p>
            <h4>6. Ihr Recht auf Widerspruch</h4>
            <h6>Information über das Widerspruchsrecht nach Artikel 21 DSGVO</h6>
            <p>
              Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung Sie betreffender personenbezogener Daten, die aufgrund von Artikel 6 Abs. 1 Buchstabe f der DSGVO (Verarbeitung zur Wahrung der berechtigten Interessen der verantwortlichen Stelle oder eines Dritten) erfolgt, Widerspruch einzulegen. Legen Sie Widerspruch ein, werde ich Ihre personenbezogenen Daten nicht mehr verarbeiten, es sei denn, ich kann zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
            </p>
            <h4>7. Datenverarbeitung über meine Webseite</h4>
            <p>
              Auch über meine Internetseite unter www.meyer-beer.de erfolgt die Verarbeitung bestimmter personenbezogener Daten, u.a. der IP-Adresse der Webseiten-Besucher*innen. Ergänzende Datenschutzhinweise finden Sie daher online unter <Link to="/datenschutzerklaerung">www.meyer-beer.de/datenschutzerklaerung</Link>.
            </p>
          </div>
        </div>
      </div>
      <Contact
        backgroundColor="white"
        buttonType="inquiry"
        />
    </div>
  );
}

export default InformationObligation;
