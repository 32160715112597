import React from 'react';
import { isNotAnEmptyString, validateMail } from '../../../helper/helper';

const TextField = ({id, caption, placeholder, required, textarea, isDirty = false}) => {
  const isTextfieldValid = () => {
    if(isDirty) {
      const value = document.getElementById(`input-${id}`).value;
      if(id === 'mail') {
        return validateMail(value);
      }
      return isNotAnEmptyString(value);
    }
    return true;
  }

  return (
    <>
      <h5>
          {caption}
          {required && (<span className="text-color-required">*</span>)}
      </h5>
      {!textarea
          ? (<input type="text" id={`input-${id}`} name={id} placeholder={placeholder} className={!isTextfieldValid() ? "border-color-red" : ""} />)
          : (<textarea id={`input-${id}`} name={id} placeholder={placeholder} />)}
    </>
  );
}
  
export default TextField;
