import { ReactComponent as LogoMeyer } from './../../assests/Meyer.svg';
import { ReactComponent as LogoBeer } from './../../assests/Beer.svg';
import { Link } from 'react-router-dom';

const Footer = ({setNavigationVisible}) => {
    return (
      <div className="footer">
        <Link to="/"  onClick={() => setNavigationVisible(false)}><LogoMeyer className="footer-left"/></Link>
        <Link to="/"  onClick={() => setNavigationVisible(false)}><LogoBeer className="footer-right"/></Link>
      </div>
    );
  }
  
  export default Footer;
  