import { Link } from "react-router-dom";

const CheckboxUserAgreement = ({ id, checked, onToggleCheckbox, isDirty }) => {
    return (
        <div className="chkbox">
            <input id={id} type="checkbox"  onClick={(e) => onToggleCheckbox(e)} checked={checked}/>
            <span className={`checkmark ${isDirty && !checked && "border-color-red"}`}></span>
            <label for={id}>Ich stimme der <Link to="/datenschutzerklaerung">Datenschutzerklärung</Link> zu und bin mit der Erhebung sowie zweckgebundenen Verarbeitung meiner Daten einverstanden.<span className="text-color-required">*</span></label>
        </div>
    );
  }
    
  export default CheckboxUserAgreement;
  