import { ReactComponent as LogoPauline } from './../../assests/Pauline.svg';
import { ReactComponent as LogoCarla } from './../../assests/Carla.svg';
import { ReactComponent as NavSchliessen } from './../../assests/NavSchliessen.svg';
import { ReactComponent as Hyphen } from './../../assests/Bindestrich.svg';
import { Link } from 'react-router-dom';

const Header = ({showNav, setNavigationVisible}) => {
    return (
      <div className="header">
        <Link to="/" onClick={() => setNavigationVisible(false)}><LogoPauline className="header-left"/></Link>
        <div className="show-hide-nav-button" onClick={() => setNavigationVisible(true)} >
              <Hyphen className="header-hyphen hyphen-1" />
              <Hyphen className="header-hyphen hyphen-2" />
              <Hyphen className="header-hyphen hyphen-3" />
        </div>
        
        {showNav &&
          <div className='white-background show-hide-nav-button'>
            <NavSchliessen  onClick={() => setNavigationVisible(false)} className="header-close"/>
          </div>
        }
        <Link to="/" onClick={() => setNavigationVisible(false)}><LogoCarla className="header-right"/></Link>
      </div>
    );
  }
  
  export default Header;
  