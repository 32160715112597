import React, { useState } from "react";
import { useSwipeable } from "react-swipeable";
import { ReactComponent as Vorwaerts } from './../../assests/Vorwaerts.svg';
import { ReactComponent as Zurueck } from './../../assests/Zurueck.svg';

// https://medium.com/tinyso/how-to-create-the-responsive-and-swipeable-carousel-slider-component-in-react-99f433364aa0

const Carousel = ({ children }) => {

    const [activeIndex, setActiveIndex] = useState(0);

    const updateIndex = (newIndex) => {
        if(newIndex < 0) {
            newIndex = React.Children.count(children) -1;
        } else if (newIndex >= React.Children.count(children)) {
            newIndex = 0;
        }

        setActiveIndex(newIndex);
    }

    const handlers = useSwipeable({
        onSwipedLeft: () => updateIndex(activeIndex + 1),
        onSwipedRight: () => updateIndex(activeIndex - 1),
    })

    return (
        <div
            className="carousel"
            {...handlers}
        >
           <div className="inner" style={{ transform: `translateX(-${activeIndex * 100}%)` }}>
                {React.Children.map(children, (child, index) => {
                    return React.cloneElement(child, { width: "100%" });
                })}
           </div>
           <div className="indicators">
               <button className="arrow" onClick={() => updateIndex(activeIndex - 1)}>
                   <Zurueck />
               </button>
               <div>
                {React.Children.map(children, (child, index) => {
                    return (
                        <button className={index === activeIndex ? "circle circle__active" : "circle"} onClick={() => updateIndex(index)} />
                    )
                })}
               </div>
               <button className="arrow" onClick={() => updateIndex(activeIndex + 1)}>
                   <Vorwaerts />
               </button>
           </div>
        </div>
    )
};

export default Carousel;

export const CarouselItem = ({ children, width }) => {
    return (
        <div className="carousel-item" style={{ width: width }}>
            {children}
        </div>
    );
};