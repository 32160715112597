import React, { useState, useRef } from 'react';
import {
  Switch,
  Route,
  HashRouter
} from "react-router-dom";
import './style/css/main.css';
import Header from './components/header';
import Nav from './components/nav';
import AboutMe from './pages/aboutMe';
import Home from './pages/home';
import Footer from './components/footer';
import Inquire from './pages/inquire';
import DataPrivacyStatement from './pages/dataPrivacyStatement';
import Imprint from './pages/imprint';
import AGB from './pages/agb';
import InformationObligation from './pages/informationObligation';
import ScrollToTop from './utils/ScrollToTop';

const App = () => {
  const [navigationVisible, setNavigationVisible] = useState(false);

  const onSetNavigationVisible = (visible) => setNavigationVisible(visible)

  const bottomRef = useRef();

  const scrollToBottom = () => {
    bottomRef.current.scrollIntoView({ behavior: "smooth" });
  }

  return (
    <HashRouter>
      <div>
        <Header showNav={navigationVisible} setNavigationVisible={onSetNavigationVisible} />
        <Nav showNav={navigationVisible} setNavigationVisible={onSetNavigationVisible} scrollToBottom={scrollToBottom}/>
        <ScrollToTop>
          <Switch>
            <Route path="/ueber_mich">
              <AboutMe />
            </Route>
            <Route path="/anfrage">
              <Inquire />
            </Route>
            <Route path="/impressum">
              <Imprint />
            </Route>
            <Route path="/datenschutzerklaerung">
              <DataPrivacyStatement />
            </Route>
            <Route path="/agb">
              <AGB />
            </Route>
            <Route path="/informationspflicht">
              <InformationObligation />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </ScrollToTop>
        <div className="bottomContainerElement" ref={bottomRef} />
      </div>
      <Footer setNavigationVisible={onSetNavigationVisible} />
    </HashRouter>
  );
}

export default App;
